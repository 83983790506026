.service{
    width: 200px;
    height: 200px;
    border: solid rgb(225, 225, 225) 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-radius: 10px;
    /* box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px; */
    cursor: pointer;
    text-decoration: none;
}

.service:hover{
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.service img{
    width: 80px;
}

.service p{
    font-size: 22px;
    color: black;
    margin: 0;
    text-align: center;
}
