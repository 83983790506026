.tag-checker--intro .dropdowns-btns{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 170px;
}

.tag-checker--intro .dropdown-menu{
    background-color: #6a6791;
}

.tag-checker--intro .dropdown-link{
    color: #FFFFFF;
    text-decoration: none;
}

.tag-checker--intro .dropdown-link:hover,
.tag-checker--intro .dropdown-link:active,
.tag-checker--intro .dropdown-link:focus,
.tag-checker--intro .dropdown-link:checked{
    color: #c5c5c5 !important;
}

.tag-checker--intro .dropdown-option{
    width: auto;
    color: #ececec !important;
}

.tag-checker--intro .dropdown-option:hover{
    background-color: #77759b;
}

.tag-checker--intro .dropdown-option:active,
.tag-checker--intro .dropdown-option:focus{
    background-color: #6359E9;
}
