.login{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    box-sizing: border-box;
    position: absolute;
    height: 100vh;
    background-color: #141332;
    background-image: url('assets/background.svg');
    background-repeat: no-repeat;
    background-position: bottom 0 left 0;
    background-size: 100%;
}

@media screen and (min-width: 1300px){
    .login{
        padding-top: 80px;
    }
}

.login--form{
    width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login--form h1{
    font-weight: 400;
    font-size: 50px;
    line-height: 80px;
    color: #FFFFFF;
    margin-bottom: 40px;
}

.login--form p{
    font-family: 'Lexend Deca';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    color: #FFFFFF;
    margin: 0 0 10px 0;
}

.login--form-inputs{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.login--form-inputs input{
    width: 300px;
    background: #6a67918e;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lexend Deca';
    color: #FFFFFF;
    padding: 15px;
    margin-top: 20px;
    box-sizing: border-box;
}

.login--form-inputs input::placeholder{
    color: #e4e4e4;
}

.login--form-inputs button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 30px;
    font-family: 'Lexend Deca';
    cursor: pointer;
}

.login--form-inputs button:hover{
    background: #554dc9;
}

.error,
.success{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(180, 57, 57, 0.447) !important;
    border: none;
    margin-top: 30px;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 15px;
    color: #FFFFFF;
    width: 300px;
}

.error p,
.success p{
    margin: 0;
}

.success{
    background-color: rgba(69, 209, 85, 0.447) !important;
}

.success a{
    color: #FFFFFF;
    text-decoration: underline;
}

.from--login{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}