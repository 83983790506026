.opt-out h1 {
    color: #FFFFFF;
    font-size: 32px !important;
    margin: 0;
}

.opt-out h2, p{
    color: #FFFFFF;
}

.opt-out--intro{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.opt-out--intro h1{
    margin-right: auto;
    text-align: left;
    margin: 0;
    color: #FFFFFF;
    font-size: 32px;
}

.opt-out--intro > .add-record-btn{
    padding: 5px 15px;
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    font-size: 20px;
    background-color: rgb(99, 89, 233);
}

.opt-out--intro > .add-record-btn:hover{
    background-color: rgb(116, 108, 232);
}

.opt-out-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #1D1D41;
    border-radius: 16.2891px;
    color: #FFFFFF;
    margin-top: 20px;
    box-sizing: border-box;
  }

.opt-out-table{
    width: 100% !important;
}

.opt-out-table th{
    font-size: 17px;
}

.opt-out-table th, .opt-out-table td{
    padding: 22px 22px 22px 60px !important;
    text-align: start;
}

.opt-out-table tbody tr{
    border-top: #141332 3px solid;
    border-bottom: #141332 3px solid;
    /* cursor: pointer; */
}

.opt-out-table tbody tr:hover{
    background-color: #29294e;
    cursor: pointer;
}

/* .opt-out-table tbody tr:hover{
    background-color: #373758;
} */

.search-group{
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-icon{
    width: 20px;
    padding-bottom: 5px;
    border-bottom: #FFFFFF solid 2px;
}

.search_icon_close{
    cursor: pointer;
}

.search-input{
    border: none;
    border-bottom: #FFFFFF solid 2px;
    background: transparent;
    color: #FFFFFF;
    padding: 0 0 5px 0px;
    width: 100%;
}

.search-input:focus::placeholder {
    color: transparent;
  }

.search-input:focus{
    outline: none;
}

.search-input::placeholder{
    color: #FFFFFF;
}

.load-more-group{
    width: 100%;
    display: flex;
    justify-content: center;
}

.load-more-button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    font-family: 'Lexend Deca';
    cursor: pointer;
    text-align: center;
}

.load-more-button:hover{
    background: #554dc9;
}

.open-letter-btn,
.open-outer-btn{
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.open-letter-btn{
    margin-right: 8px;
    background-color: #6359E9;
}
.open-letter-btn:hover{
    background-color: #554dc9
}

.open-outer-btn{
    background-color: #3c3c5e;
    margin-left: 8px;
}

.open-outer-btn:hover{
    background-color: #2e2e4a;
}

.td-loading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.td-loading p{
    margin: 0;
    padding: 0;
    margin-left: 10px;
}

.link-wrapper{
    display: table-row;
    vertical-align: inherit;
}

.opt-out--bottom{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.opt-out--bottom > p{
    margin: 0;
}

.opt-out--bottom button{
    color: #FFFFFF;
    padding: 10px 15px;
    background-color: #6359e9;
    border: none;
    border-radius: 8px;
}

.opt-out--bottom button:hover{
    background-color: rgb(122, 114, 238);
}

.opt-out--bottom button:disabled,
.opt-out--bottom button[disabled],
.opt-out--bottom button:disabled:hover,
.opt-out--bottom button[disabled]:hover{
    background-color: rgb(137, 133, 188);
    cursor: wait;
}

.tbb-toggle{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.tbb-toggle button{
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 0 12px 0;
    cursor: pointer;
    margin: 0;
    border: none;
    color: #FFFFFF;
}

.opt-out .create-record{
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0;
}

.opt-out .create-record > input{
    padding: 10px 17px;
    border-radius: 6px;
    border: none;
    background: #29294e;
    color: #FFFFFF;
}
.opt-out .create-record > input::placeholder{
    color: #d4d4d4;
}

.opt-out .create-record > button{
    padding: 8px 20px;
    border-radius: 7px;
    border: none;
    background: #6359e9;
    color: #FFFFFF;
}

.opt-out .create-record > button:hover{
    background: #7b72fa;
}