.bucket-selectors{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 30px;
}

.bucket-selectors button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 30px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 65%;
}

.back-to-bucket-selectors{
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.back-to-bucket-selectors button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 30px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spreadsheet-controls{
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.spreadsheet-content{
    overflow: auto;
}

.file-selector{
    overflow: auto;
    margin-top: 30px;
    
}

.file-selector-element{
    background-color: white;
    padding: 5px;
    border: solid 1px black;
    cursor: pointer;
    
}