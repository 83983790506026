.generate-proof--intro{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
}

.generate-proof--intro h1{
    margin-right: auto;
    text-align: left;
    margin: 0;
    color: #FFFFFF;
    font-size: 32px;
}

.generate-proof--intro .dropdown-menu{
    background-color: #6a6791;
}

.generate-proof--intro .dropdown-link{
    color: #FFFFFF;
    text-decoration: none;
}

.generate-proof--intro .dropdown-link:hover,
.generate-proof--intro .dropdown-link:active,
.generate-proof--intro .dropdown-link:focus,
.generate-proof--intro .dropdown-link:checked{
    color: #c5c5c5 !important;
}

.generate-proof--intro .dropdown-option{
    width: auto;
    color: #ececec !important;
}

.generate-proof--intro .dropdown-option:hover{
    background-color: #77759b;
}

.generate-proof--intro .dropdown-option:active,
.generate-proof--intro .dropdown-option:focus{
    background-color: #6359E9;
}

.generate-proof {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #1D1D41;
  border-radius: 16.2891px;
  color: #FFFFFF;
  padding: 50px;
  box-sizing: border-box;
}

.generate-proof h1{
    margin: 0 0 30px 0;
    font-size: 30px;
    font-weight: 500;
}

.generate--form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.generate--form input[type="file"]{
    width: 300px;
    background: #6a67918e;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lexend Deca';
    color: #FFFFFF;
    padding: 15px;
    margin-top: 15px;
    box-sizing: border-box;
    cursor: pointer;
}

.generate--form input[type="file"]::-webkit-file-upload-button {
    visibility: hidden;
  }


.generate--form input{
    width: 300px;
    background: #6a67918e;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lexend Deca';
    color: #FFFFFF;
    padding: 15px;
    margin-top: 10px;
    margin-bottom: 30px;
    box-sizing: border-box;
}

.generate--form input::placeholder{
    color: #e4e4e4b9;
}

.generate--form input:-webkit-autofill,
.generate--form input:-webkit-autofill:hover, 
.generate--form input:-webkit-autofill:focus, 
.generate--form input:-webkit-autofill:active{
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: #FFFFFF !important;
}

.generate--form button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    font-family: 'Lexend Deca';
    cursor: pointer;
}

.generate--form button:hover{
    background: #554dc9;
}

.generate--form button div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

/* Loader / spinner */
.generate--form button div div{
    margin-right: 10px;
}

.advanced-options-label{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

.advanced-options-label p,  
.advanced-options-label input{
    margin: 0;
    padding: 0;
    width: auto;
    margin-right: 10px;
}

.proof-result,
.errors{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(69, 209, 85, 0.447);
    border: none;
    margin-top: 30px;
    padding: 30px;
    box-sizing: border-box;
    border-radius: 15px;
    color: #FFFFFF;
    width: 100%;
}

.proof-result p{
    cursor: pointer;
    text-decoration: underline;
    margin: 0;
}

.margin-top-20{
    margin-top: 20px !important;
}

.proof-result div{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.errors{
    background-color: rgba(180, 57, 57, 0.447) !important;
}
.errors p{
    margin: 0;
}

.form-group{
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 12px;
}