.sftpUserForm{
    width: 500px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.sftpUserForm input{
    border-radius: 50px;
    width: 300px;
    background: #6a67918e;
    border-radius: 10px;
    border: none;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Lexend Deca';
    color: #FFFFFF;
    padding: 15px;
    box-sizing: border-box;
}

.sftpUserForm button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 30px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    padding: 10px;
    width: 40%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sftpUserForm label{
    margin-top: 20px;
    color: #fff;
}

.checkBox{
    height: 30px;
    max-width: 30px;
    border-radius: 10px;
    margin-left: 55px;
}