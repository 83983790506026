.proofs h1 {
    color: #FFFFFF;
    font-size: 32px !important;
    margin: 0;
}

.proofs h2, p{
    color: #FFFFFF;
}

.proofs-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #1D1D41;
    border-radius: 16.2891px;
    color: #FFFFFF;
    margin-top: 20px;
    box-sizing: border-box;
  }

.proofs-table{
    width: 100% !important;
}

.proofs-table th{
    font-size: 17px;
}

.proofs-table th, .proofs-table td{
    padding: 22px !important;
    text-align: center;
}

.proofs-table tbody tr{
    border-top: #141332 3px solid;
    border-bottom: #141332 3px solid;
    /* cursor: pointer; */
}

/* .proofs-table tbody tr:hover{
    background-color: #373758;
} */

.search-group{
    width: 350px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.search-icon{
    width: 20px;
    padding-bottom: 5px;
    border-bottom: #FFFFFF solid 2px;
}

.search_icon_close{
    cursor: pointer;
}

.search-input{
    border: none;
    border-bottom: #FFFFFF solid 2px;
    background: transparent;
    color: #FFFFFF;
    padding: 0 0 5px 0px;
    width: 100%;
}

.search-input:focus::placeholder {
    color: transparent;
  }

.search-input:focus{
    outline: none;
}

.search-input::placeholder{
    color: #FFFFFF;
}

.load-more-group{
    width: 100%;
    display: flex;
    justify-content: center;
}

.load-more-button{
    background: #6359E9;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    width: 300px;
    color: #FFFFFF;
    font-weight: 400;
    font-size: 16px;
    padding: 15px;
    text-align: center;
    border: none;
    margin-top: 20px;
    font-family: 'Lexend Deca';
    cursor: pointer;
    text-align: center;
}

.load-more-button:hover{
    background: #554dc9;
}

.open-letter-btn,
.open-outer-btn{
    color: #FFFFFF;
    border: none;
    padding: 10px;
    border-radius: 5px;
}

.open-letter-btn{
    margin-right: 8px;
    background-color: #6359E9;
}
.open-letter-btn:hover{
    background-color: #554dc9
}

.open-outer-btn{
    background-color: #3c3c5e;
    margin-left: 8px;
}

.open-outer-btn:hover{
    background-color: #2e2e4a;
}

.td-loading{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.td-loading p{
    margin: 0;
    padding: 0;
    margin-left: 10px;
}