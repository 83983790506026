.side-bar{
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background: #1D1D41;
    border: none;
    margin: none;
    color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.side-bar--logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 200px;
    margin-top: 30px;
}

.side-bar--logo img{
    width: 40px;
    margin-right: 12px;
}

.side-bar--logo h1{
    font-weight: 500;
    font-size: 28px;
    margin: 0;
    padding: 0;
}

.side-bar--menu{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    height: 100%;
}

.side-bar--menu-item{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 220px;
    height: 50px;
    margin-bottom: 10px;
    cursor: pointer;
    padding: 0px 15px;
    box-sizing: border-box;
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    text-decoration: none;
}

.side-bar--menu-item:hover{
    background: #6359E9;
    color: #FFFFFF;
}

.side-bar--menu-item img{
    width:20px;
    margin-right: 15px;
} 

.side-bar--menu-item p{
    margin: 0;
} 

.back{
    margin-top: auto !important;
}