.opt-out-record{
    position: relative;
}

.opt-out-record > td > input{
    display: inline-block;
    border: none;
    background: rgb(55, 55, 100);
    color: #FFFFFF;
    padding: 8px 15px;
    width: 150px;
    border-radius: 6px;
}

.opt-out-record > .icons{
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
}

.opt-out-record > .icons> img{
    margin: 0 5px 0 5px;
}

.opt-out-record > td > .buttons{
    position: absolute;
    right: 0;
    bottom: 0;
}