.check-tag{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 20px;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
}

.check-tag:hover{
    background-color: #272751;
    text-align: start;
}

.check-tag p{
    margin: 0;
    margin-left: 10px;
    width: 100%;
}