.tag-section .details-container{
    width: 700px;
    margin-left: 50px;
    cursor: pointer;
    padding: 30px;
    border-radius: 20px;
    position: relative;
    box-sizing: border-box;
    overflow-x: auto;
}

.tag-section .details-container:hover{
    background-color: #13152936;
}

.tag-section .details-container .close-icon{
    position: absolute;
    top: 10px;
    right: 15px;
}

.tag-section .details-container table{
    padding: 20px;
    width: 100%;
}

.tag-section .details-container .details-intro > p{
    font-weight: bold;
}

.tag-section .details-container table tr{
    border: 3px solid #141332;
    padding: 20px;
}

.tag-section .details-container table th{
    padding: 20px 20px;
}

.tag-section .details-container table thead tr{
    border: 3px solid #141332;
    border-radius: 10px !important;
}


.tag-section .details-container table td{
    padding: 15px 20px;
}