.services{
    width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.navbar{
    width: 100%;
}

.services-container{
    padding-top: 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 50px;
}
