.account-recs{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #1D1D41;
    border-radius: 16.2891px;
    color: #FFFFFF;
    box-sizing: border-box;
    min-height: 100%;
}

.account-recs--intro{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 20px;
}